<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Keresés</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Keresés"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email',
          },
        },
        {
          label: 'Date',
          field: 'startDate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Salary',
          field: 'salary',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Salary',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Műveletek',
          field: 'action',
        },
      ],
      rows: [
        {
          id: 1,
          avatar: require('@/assets/images/avatars/10-small.png'),
          fullName: "Korrie O'Crevy",
          email: 'kocrevy0@thetimes.co.uk',
          startDate: '09/23/2016',
          salary: '$23896.35',
          status: 'Professional',
        },
        {
          id: 2,
          avatar: require('@/assets/images/avatars/1-small.png'),
          fullName: 'Bailie Coulman',
          email: 'bcoulman1@yolasite.com',
          startDate: '05/20/2018',
          salary: '$13633.69',
          status: 'Resigned',
        },
        {
          id: 3,
          avatar: require('@/assets/images/avatars/9-small.png'),
          fullName: 'Stella Ganderton',
          email: 'sganderton2@tuttocitta.it',
          startDate: '03/24/2018',
          salary: '$13076.28',
          status: 'Professional',
        },
        {
          id: 4,
          avatar: require('@/assets/images/avatars/3-small.png'),
          fullName: 'Dorolice Crossman',
          email: 'dcrossman3@google.co.jp',
          startDate: '12/03/2017',
          salary: '$12336.17',
          status: 'Applied',
        },
        {
          id: 5,
          avatar: require('@/assets/images/avatars/4-small.png'),
          fullName: 'Harmonia Nisius',
          email: 'hnisius4@gnu.org',
          startDate: '08/25/2017',
          salary: '$10909.52',
          status: 'Professional',
        },
        {
          id: 6,
          avatar: require('@/assets/images/avatars/5-small.png'),
          fullName: 'Genevra Honeywood',
          email: 'ghoneywood5@narod.ru',
          startDate: '06/01/2017',
          salary: '$17803.8',
          status: 'Current',
        },
        {
          id: 7,
          avatar: require('@/assets/images/avatars/7-small.png'),
          fullName: 'Eileen Diehn',
          email: 'ediehn6@163.com',
          startDate: '10/15/2017',
          salary: '$18991.67',
          status: 'Professional',
        },
        {
          id: 8,
          avatar: require('@/assets/images/avatars/9-small.png'),
          fullName: 'Richardo Aldren',
          email: 'raldren7@mtv.com',
          startDate: '11/05/2016',
          salary: '$19230.13',
          status: 'Rejected',
        },
        {
          id: 9,
          avatar: require('@/assets/images/avatars/2-small.png'),
          fullName: 'Allyson Moakler',
          email: 'amoakler8@shareasale.com',
          startDate: '12/29/2018',
          salary: '$11677.32',
          status: 'Applied',
        },
        {
          id: 10,
          avatar: require('@/assets/images/avatars/6-small.png'),
          fullName: 'Merline Penhalewick',
          email: 'mpenhalewick9@php.net',
          startDate: '04/19/2019',
          salary: '$15939.52',
          status: 'Professional',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
}
</script>
